<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" label="부서" name="deptCd" v-model="searchParam.deptCd" :isFirstValue="false" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-tree-table
          title="부서목록"
          parentProperty="upDeptCd"
          customID="deptCd"
          :columns="gridDept.columns"
          :data="gridDept.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="false"
          :isExcelDown="false"
          :filtering="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getAllList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-table
          ref="grid"
          title="부서별 관리감독자 목록"
          tableId="grid"
          :columns="grid.columns"
          :data="grid.data"
          :editable="editable"
          :checkClickFlag="false"
          rowKey="userId"
          selection="multiple"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <div>
              <q-btn-group outline>
                <c-btn label="추가" icon="add" @btnClicked="addRow" v-if="editable" />
                <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable" />
              </q-btn-group>
            </div>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dept-manage-list',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
      },
      gridDept: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'deptBan',
          //   field: 'deptBan',
          //   label: '반',
          //   align: 'center',
          //   type: 'text',
          //   style: 'width:150px',
          //   sortable: true,
          // },
          // {
          //   name: 'deptJo',
          //   field: 'deptJo',
          //   label: '조',
          //   style: 'width:150px',
          //   align: 'center',
          //   type: 'text',
          //   sortable: true,
          // },
          {
            name: 'jobName',
            field: 'jobName',
            label: '분류',
            align: 'center',
            sortable: true,
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직급',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '이름',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      deptlistUrl: '',
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        isFull: false,
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.deptlistUrl = selectConfig.mdm.dept.list.url;
      this.listUrl = selectConfig.mdm.deptmanager.list.url;
      this.saveUrl = transactionConfig.mdm.deptmanager.save.url;
      this.deleteUrl = transactionConfig.mdm.deptmanager.delete.url;
      this.getDeptList();
      this.getList();
    },
    getAllList() {
      this.getDeptList();
      this.getList();
    },
    getDeptList() {
      this.rowNotSelected = true;
      this.$http.url = this.deptlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {plantCd: this.searchParam.plantCd, deptCd: this.searchParam.deptCd, useFlag:'Y'};
      this.$http.request((_result) => {
        this.gridDept.data = _result.data;
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
      this.grid.data = _result.data;
      },);
    },
    addRow() {
      this.popupOptions.title = '안전관리자 추가';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    rowClick(row) {
      // 상세조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      if (row.deptCd == row.plantCd) {
        this.$http.param = {plantCd: row.plantCd};
      } else {
        this.$http.param = {deptCd: row.deptCd};
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { userId: item.userId }) === -1) {
            saveData.push({
              plantCd: item.plantCd,  // 사업장코드
              plantName: item.plantName,  // 사업장코드
              deptCd: item.deptCd,  // 부서코드
              deptName: item.deptName,  // 부서코드
              deptBan: '',  // 반
              deptJo: '',  // 조
              userId: item.userId,  // 안전관리자 아이디
              userName: item.userName,  // 안전관리자 아이디
              jobName: item.jobName,  // 안전관리자 아이디
              spotName: item.spotName,  // 안전관리자 아이디
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
        this.$http.url = this.saveUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
    saveRow() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getList();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getItemList({code: selectData[0].codeGrpCd});
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
